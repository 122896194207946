
.container {
    display: block;
    width: 100%;
    max-width: 1400px;
    margin-left: auto;
    margin-right: auto;
    padding: var(--space-sm);

    .controls_container {
        width: 100%;
        max-width: 400px;
        margin-left: auto;
        margin-right: auto;
        padding-bottom: var(--space-lg);

        h1 {
            margin: 0;
        }

        .controls {
            width: 100%;
            padding: var(--space-sm);
            border-radius: var(--space-sm);
            background-color: rgba(0, 0, 0, 0.3);
            margin-top: var(--space-2xs);
        }
    }

    .side_by_side_layout {
        display: flex;
        flex-direction: row;
        width: 100%;
        gap: var(--space-sm);

        .side_by_side_leaderboard {
            flex: 1 1 0;
        }
    }
}
