@use "@/scss/config" as *;

.button {
  display: inline-block;
  font-size: var(--text-md);
  font-weight: bold;
  text-align: center;
  color: var(--color-contrast-high);
  background-color: var(--color-contrast-below);
  border-bottom: var(--space-3xs) var(--color-contrast-between_lower_low) solid;
  border-radius: var(--space-sm);
  padding: var(--space-md) var(--space-lg);
  cursor: pointer;

  &, &:hover {
    text-decoration: none;
  }

  &.large_button, &.wide_button {
    padding: var(--space-xs) var(--space-lg);
    border-radius: var(--space-2xs);
  }
  &.large_button {
    font-size: var(--text-lg);
  }
  &.wide_button {
    font-size: var(--text-lg);
    width: 100%;
  }

  &.small_button {
    padding: var(--space-xs) var(--space-sm);
    border-radius: var(--space-2xs);
  }

  &.tiny_button {
    font-size: var(--text-base);
    padding: var(--space-2xs) var(--space-sm);
    border-radius: var(--space-2xs);
  }


  &:hover {
    color: var(--color-contrast-higher);
    background-color: var(--color-contrast-medium);
  }
  &:active {
    background-color: var(--color-contrast-medium);
  }

  /* Basic Buttons */
  &.basic {
    color: var(--color-contrast-high);
    background-color: var(--color-contrast-low);
    border-bottom-color: var(--color-contrast-lower);

    &:hover {
      color: var(--color-contrast-higher);
      background-color: var(--color-contrast-below);
    }
    &:active {
      background-color: var(--color-contrast-low);
    }
  }

  /* Call-to-Action Buttons */
  &.cta {
    font-size: var(--text-lg);
    color: var(--color-accent-darkest);
    background-color: var(--color-accent);
    border-bottom-color: var(--color-accent-darker);

    &.small_button {
      font-size: var(--text-md);
    }
    &.tiny_button {
      font-size: var(--text-base);
    }

    &:hover {
      color: var(--color-accent-lightest);
    }
    &:active {
      background-color: var(--color-accent-below);
    }
  }

  /* Accent Buttons */
  &.secondary {
    font-size: var(--text-lg);
    color: var(--color-primary-darkest);
    background-color: var(--color-primary);
    border-bottom-color: var(--color-primary-darker);

    &.small_button {
      font-size: var(--text-md);
    }
    &.tiny_button {
      font-size: var(--text-base);
    }

    &:hover {
      color: var(--color-primary-lightest);
    }
    &:active {
      background-color: var(--color-primary-dark);
    }
  }

  /* Cancel buttons */
  &.cancel {
    font-size: var(--text-lg);
    color: var(--color-error-darkest);
    background-color: var(--color-error);
    border-bottom-color: var(--color-error-darker);

    &.small_button {
      font-size: var(--text-md);
    }
    &.tiny_button {
      font-size: var(--text-base);
    }

    &:hover {
      color: var(--color-error-lightest);
    }
    &:active {
      background-color: var(--color-error-dark);
    }
  }
}
