@use "@/scss/config" as *;

.container {
    position: relative;
    width: 100%;

    &.fullscreen {
        min-height: 100vh;
    }

    h1, h2 {
        text-shadow: 0 0.05em 0.1em rgba(0, 0, 0, 0.4);
    }

    header {
        background: transparent;
        @include min-width(sm) {
            padding: 0 var(--space-md);
        }
    }

    .background {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -100;
        background-color: var(--color-bg-lighter);
        background-repeat: repeat;
        background-size: 16rem;
        pointer-events: none;
    }
}
