
.banner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: var(--space-sm);
    font-size: var(--text-xl);
    width: 6.5em;
    margin-left: auto;
    margin-right: auto;

    .logo {
        display: block;
        width: 100%;
        height: 2.5em;
        font-size: inherit;
        text-decoration: none;
        background-size: contain;
        text-indent: -999em;
    }
}
