
.form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
    width: 100%;
    padding-top: var(--space-sm);
}

.status {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: left;
    margin-top: var(--space-2xs);
    margin-bottom: var(--space-sm);

    p.error, p.success {
        font-size: var(--text-base);
        min-height: 1em;
        margin: 0;
    }

    p.error {
        color: var(--color-error-darker);
    }

    .check {
        display: inline;
        width: 2.5em;
        height: 2.5em;
        margin-right: var(--space-2xs);
        fill: #5ad64f;
    }
}
