@use "@/scss/config" as *;

.byline {
    display: block;
    width: 100%;
    font-size: 0.55em;
    font-weight: bold;
    text-align: right;
    margin-top: var(--space-3xs);

    .name {
        display: inline-block;
        padding-bottom: var(--space-sm);
    }
    &, .name {
        color: var(--color-contrast-high);
        text-shadow:
                -0.075em -0.075em 0 #000,
                0.075em -0.075em 0 #000,
                -0.075em 0.075em 0 #000,
                0.075em 0.075em 0 #000;
    }
    a:hover {
        color: var(--color-accent);
        text-decoration: none;
    }

    @include min-width(sm) {
        margin-top: 0;
    }
}
