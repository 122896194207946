@use "@/scss/config" as *;

.ruleset {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    min-width: 4em;
    max-width: 4em;
    margin: 0;
    gap: 0;

    font-size: var(--text-base);
    text-align: left;
    font-weight: bold;
    color: var(--color-contrast-high);

    @include min-width(sm) {
        flex-direction: row;
        gap: var(--space-2xs);
        min-width: 7.5em;
        max-width: 7.5em;
    }

    img {
        width: 3em;
        min-width: 3em;
        max-width: 3em;
        object-fit: contain;
    }
    p {
        margin: 0;
    }
}
