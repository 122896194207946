
.title_section {
    width: 100%;
    text-align: center;
    font-size: var(--text-xl);

    h2 {
        margin: var(--space-md) 0 var(--space-xs);
    }
}
