
.container {
    display: block;

    &.margins {
        margin-top: var(--space-md);
        margin-bottom: var(--space-lg);
    }

    .quote {
        display: block;
        margin-bottom: 0;
        font-size: var(--text-lg);
        padding-left: 0.6rem;
        border-left: 0.25rem solid var(--color-contrast-low);
    }

    .author {
        font-size: var(--text-md);
        font-weight: normal;
        color: var(--color-contrast-medium);
        padding-left: 0.5em;
        .dash {
            padding-right: 0.2em;
        }
    }

    &.regular_size {
        .quote {
            font-size: var(--text-md);
        }
        .author {
            font-size: var(--text-base);
        }
    }
}
