@use "@/scss/config" as *;

.dir {
    font-size: var(--text-md);

    &, .dir_piece_container, .dir_piece {
        display: flex;
        flex-direction: row;
    }
    &, .dir_piece_container {
        align-items: center;
    }
    .dir_piece {
        align-items: center;
    }

    .dir_piece {
        max-width: max-content;
        white-space: nowrap;
        cursor: pointer;
        color: var(--color-contrast-high);

        svg, span {
            font-size: inherit;
        }

        &:hover {
            text-decoration: underline;
            color: var(--color-contrast-higher);
        }
    }

    @include max-width(sm) {
        margin-top: var(--space-sm);
    }
}

.back_button {
    &:hover {
        cursor: pointer;
        color: var(--color-contrast-higher);
        text-decoration: underline;
    }
}
