
.dots_container {
    display: inline-block;
    position: relative;

    .dots_spacer {
        display: inline-block;
        color: transparent;
    }
    .dots {
        position: absolute;
        left: 0;
        top: 0;
        display: block;
    }
}
