@use "@/scss/config" as *;

.article_container {
    width: 100%;
    max-width: 920px;
    min-height: 80vh;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: var(--space-lg);
    text-align: left;

    article, div.not_article {
        width: 100%;

        section {
            max-width: 780px;
            margin-left: auto;
            margin-right: auto;
            margin-bottom: var(--space-md);
            padding: var(--space-xs);
            padding-top: var(--space-sm);

            border-radius: var(--space-sm);
            background-color: var(--color-article);
            box-shadow: var(--shadow-md);

            @include min-width(sm) {
                padding: var(--space-md);
            }
        }

        h1 {
            margin-top: var(--space-sm);

            @include min-width(sm) {
                margin-top: 0;
            }
        }

        figcaption {
            color: var(--color-contrast-high);
            padding: 0 var(--space-md);
        }

        section figcaption {
            color: var(--color-contrast-medium);
        }

        & > p, section > p {
            padding-left: var(--space-sm);
            padding-right: var(--space-sm);

            @include min-width(sm) {
                padding-left: var(--space-md);
                padding-right: var(--space-md);
            }
        }

        & > ul,
        & > ol,
        section > ul,
        section > ol,
        & > div > ul,
        & > div > ol,
        section > div > ul,
        section > div > ol {
            margin-left: var(--space-sm);
            margin-right: var(--space-sm);

            @include min-width(sm) {
                margin-left: var(--space-md);
                margin-right: var(--space-md);
            }
        }
    }
}
