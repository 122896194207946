@use "@/scss/config" as *;

.container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.75rem;

    @include min-width(sm) {
        flex-direction: row;
    }

    .game_count_blocks {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: var(--space-xs);
        font-size: var(--text-base);
        flex: 1;

        .game_count_block {
            display: flex;
            flex-direction: column;
            padding: 0;
            color: var(--color-contrast-high);
            border-radius: var(--space-sm);
            background-color: var(--color-bg-dark);
            overflow: hidden;
            width: 100%;

            .block_high {
                width: 100%;
                padding: var(--space-3xs) var(--space-xs);
                font-size: var(--text-md);
                color: var(--color-contrast-high);
                background-color: var(--color-bg-darker);
            }

            .block_low {
                display: flex;
                flex-direction: row;

                .rules_icon {
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: center;
                    padding: var(--space-xs);
                    padding-right: 0;

                    img {
                        width: 3em;
                        min-width: 3em;
                        max-width: 3em;
                        object-fit: contain;
                    }
                }

                .stats {
                    padding: var(--space-3xs);
                    padding-left: 0;

                    p {
                        padding: 0 var(--space-xs);
                        padding-top: var(--space-3xs);
                        margin: 0;

                        color: var(--color-contrast-medium);
                        &.total_games {
                            color: var(--color-contrast-higher);
                        }
                        &.online_rating {
                            margin-top: var(--space-2xs);
                            margin-bottom: var(--space-3xs);
                        }
                    }
                }
            }
        }
    }
}
