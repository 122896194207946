@use "@/scss/config" as *;

.container {
  display: block;
  width: 100%;
  max-width: 540px;
  padding: var(--space-sm) var(--space-xs);
  padding-bottom: var(--space-xl);
  text-align: left;
  margin-left: auto;
  margin-right: auto;

  @include min-width(sm) {
    padding: var(--space-md);
  }

  .back {
    margin-bottom: var(--space-sm);
  }
}

.title_section {
  width: 100%;
  text-align: center;
}
