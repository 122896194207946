@use "@/scss/config" as *;

.container {
    display: block;
    width: 100%;
    max-width: 720px;
    padding-left: var(--space-sm);
    padding-right: var(--space-sm);
    padding-bottom: var(--space-lg);
    margin-left: auto;
    margin-right: auto;
}

.greeting {
    display: flex;
    flex-direction: column;
    align-items: center;

    h1 {
        margin-top: var(--space-md);
        font-weight: bold;
        line-height: 1.6;
        letter-spacing: 0.01em;
    }

    p {
        width: 100%;
        border-radius: var(--space-sm);
        background-color: var(--color-article);
        box-shadow: var(--shadow-md);
        padding: var(--space-xs);

        @include min-width(sm) {
            padding: var(--space-md);
        }
    }

    .logo {
        display: inline-block;
        height: 20em;
        max-width: 65%;
        width: auto;
    }

    .greeting_background {
        background-color: #241a15;

        &, .greeting_background_image {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: -100;

            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
        }
    }
}
