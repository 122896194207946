
div.card {
    color: var(--color-contrast-high);
    background-color: var(--color-bg);
    border-bottom-color: var(--color-bg-dark);

    &.dark {
        background-color: var(--color-bg-dark);
        border-bottom-color: var(--color-bg-darker);
    }
}
