
.play_container {
    margin-top: var(--space-md);
    margin-bottom: var(--space-sm);
    max-width: 42em;

    .play_hard_bot_instead {
        width: 100%;
    }
}
