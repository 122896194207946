
.container {
    display: block;
    width: 100%;
    max-width: 600px;
    border-radius: var(--space-sm);
    background-color: #0f263b;
    box-shadow: var(--shadow-md);
    margin-left: auto;
    margin-right: auto;
    margin-bottom: var(--space-md);
    padding: var(--space-md);

    .controls_container {
        width: 100%;

        h1 {
            margin: 0;
        }
        h2 {
            margin: var(--space-sm) 0;
            margin-top: var(--space-md);
        }
    }

    p {
        margin: var(--space-sm) 0;
    }
}
