@use "@/scss/config" as *;

.container {
    position: relative;
    flex: 0;
    height: 100%;
    background-color: var(--color-primary-darkest);

    width: 30px;
    min-width: 30px;

    @include min-width(sm) {
        width: 40px;
        min-width: 40px;
    }

    .bar {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 50%;
        background-color: var(--color-accent-dark);
        transition: height 0.3s ease-in-out;
    }

    .percentage {
        position: absolute;
        width: 100%;
        font-weight: bold;
        text-align: center;
        padding: 4px 0;
        margin: 0;
        transform: scale(0.9);

        font-size: 11px;

        @include min-width(sm) {
            font-size: 14px;
        }

        &.light {
            bottom: 0.25em;
            color: black;
        }

        &.dark {
            top: 0.25em;
            color: var(--color-contrast-high);
        }
    }
}
