@use "@/scss/config" as *;

.live_lobbies_container {
    display: flex;
    flex-direction: column;
    gap: var(--space-sm);

    .entry_list {
        display: flex;
        flex-direction: column;
        gap: var(--space-xs);
        min-height: 26em;
        overflow-y: auto;

        &.small {
            min-height: 10em;
        }

        .create_link, .total_playing {
            padding: var(--space-xs);

            &.small {
                padding: var(--space-2xs);
            }
        }

        .create_link, .total_playing, .entry {
            display: flex;
            flex-direction: row;
            align-items: stretch;
            width: 100%;
            border: 1px solid transparent;
            text-align: left;
            background-color: rgba(10, 10, 10, 0.2);
            border-radius: var(--space-xs);
            overflow: hidden;
            margin: 0;
        }

        .entry {
            min-height: 5em;

            &.small {
                min-height: 0;
            }

            gap: var(--space-xs);
            @include min-width(sm) {
                gap: var(--space-sm);
            }

            .waiting {
                color: var(--color-accent);
            }
            .highlight {
                color: var(--color-primary);
            }

            .ruleset {
                padding: var(--space-xs);
                padding-right: 0;
            }

            .details {
                display: flex;
                align-items: center;
                flex-grow: 1;

                p {
                    padding: var(--space-xs) 0;
                    font-size: var(--text-base);
                    text-align: left;
                    margin: 0;
                }
            }
            &.small .details {
                padding-left: var(--space-xs);
            }

            .play_buttons, .watch_button {
                display: flex;
                flex-direction: row;
                align-items: center;
                background-color: rgba(0, 0, 0, 0.2);
                padding: var(--space-xs);
                gap: var(--space-2xs);

                .play_buttons {
                    min-width: 7.5em;
                }

                button {
                    min-height: 2.6em;
                }

                &.play_buttons__options {
                    button {
                        padding-left: var(--space-2xs);
                        padding-right: var(--space-2xs);
                    }
                }

                .cancel, .wide_cancel {
                    display: flex;
                    height: 2.6em;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                }
                .cancel {
                    width: 2.6em;
                }
                .wide_cancel {
                    width: 100%;
                }
            }
        }
    }

    .tabs {
        display: flex;
        flex-direction: row;
        gap: var(--space-sm);

        .tab {
            cursor: pointer;
            padding: var(--space-sm);
            padding-top: 0.5em;
            padding-bottom: 0.4em;
            font-size: var(--text-base);
            font-weight: bold;
            color: var(--color-contrast-high);
            background-color: var(--color-contrast-between_lower_low);
            border-bottom: var(--space-3xs) solid var(--color-contrast-low);
            border-radius: var(--space-xs);
            flex-grow: 1;

            &.tab_active {
                background-color: var(--color-contrast-low);
                border-bottom-color: var(--color-contrast-medium);
            }
        }
    }
}

.error {
    color: var(--color-error);
}
